import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';
import type { ApiRequestOptions as TApiRequestOptions, IRequestOptions } from 'o365.pwa.declaration.sw.apiRequestOptions.ApiRequestOptions.d.ts';
import type { getAppIDFromURL as TgetAppIDFromURL } from 'o365.pwa.declaration.shared.GetAppIDFromUrl.d.ts';

import type {
    IApiDataRequestBody,
    IApiDataRequestOptions
} from 'o365.pwa.declaration.sw.apiRequestOptions.ApiDataRequestOptions.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

(() => {
    const { ApiRequestOptions } = self.o365.importScripts<{ ApiRequestOptions: typeof TApiRequestOptions }>("o365.pwa.modules.sw.apiRequestOptions.ApiRequestOptions.ts");
    const { getAppIDFromURL } = self.o365.importScripts<{ getAppIDFromURL: typeof TgetAppIDFromURL }>("o365.pwa.modules.shared.GetAppIDFromURL.ts");

    class ApiDataRequestOptions<T extends IApiDataRequestBody> implements IRequestOptions {
        public readonly appId: string;
        public readonly body: T;
        public readonly headers: Map<string, Set<string>>;


        constructor(options: IApiDataRequestOptions<T>) {
            this.appId = options.appId;
            this.body = options.body;
            this.headers = options.headers;
        }

        static async fromRequest<T extends IApiDataRequestBody>(request: Request): Promise<TApiRequestOptions<ApiDataRequestOptions<T>>> {
            const clonedRequest = request.clone();

            const appId = getAppIDFromURL(new URL(request.referrer))

            const body = await clonedRequest.json();

            const headers = new Map<string, Set<string>>();

            request.headers.forEach((value, key, _parent) => {
                if (headers.has(key) === false) {
                    headers.set(key, new Set<string>());
                }

                const values = value.split(',').map((value) => value.trim());

                values.forEach((value) => {
                    headers.get(key)!.add(value);
                });
            });

            return new ApiRequestOptions(request, new ApiDataRequestOptions<T>({
                appId,
                body,
                headers
            }));
        }

    }

    self.o365.exportScripts({ ApiDataRequestOptions });
})();